<template>
  <div>
    <zhsb-solution v-show="title == 'zhsbsolution'"></zhsb-solution>
    <zhsys-solution v-show="title == 'zhsyssolution'"></zhsys-solution>
    <zhcc-manage v-show="title == 'zhccmanage'"></zhcc-manage>
    <zhxy-manage v-show="title == 'zhxymanage'"></zhxy-manage>
    <zhyq-solution v-show="title == 'zhyqsolution'"></zhyq-solution>
    <zhwy-solution v-show="title == 'zhwysolution'"></zhwy-solution>
    <sbyw-solution v-show="title == 'sbywsolution'"></sbyw-solution>
  </div>
</template>

<script>
import ZhccManage from "../components/totalsolution/ZhccManage";
import ZhxyManage from "../components/totalsolution/ZhxyManage";
import ZhyqSolution from "../components/totalsolution/ZhyqSolution";
import ZhwySolution from "../components/totalsolution/ZhwySolution";
import SbywSolution from "../components/totalsolution/SbywSolution";
import ZhsbSolution from "../components/totalsolution/ZhsbSolution";
import ZhsysSolution from "../components/totalsolution/ZhsysSolution";

export default {
  name: "solution",
  components: {
    ZhsbSolution,
    ZhsysSolution,
    ZhccManage,
    ZhxyManage,
    ZhyqSolution,
    ZhwySolution,
    SbywSolution,

  },
  data() {
    return {
      title: "",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
         this.title = route.meta.title;
         window.scrollTo(0, 0);
      },
      immediate: true,
    },
  },
};
</script>