<template>
    <div>   <img src="@/assets/solution/yuanqu.png" alt=""></div>
</template>

<script>
export default {
    name: "smoke-solution"
}
</script>

<style scoped>
div{
    text-align: center;
}
img{
    width: 1200px;
}
</style>