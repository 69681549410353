<template>
    <div>
        <img src="@/assets/solution/shebei.png" alt="">
 
    </div>
</template>

<script>
export default {
    name: "zhsb-solution"
}
</script>

<style scoped>
div{
    text-align: center;
}
img{
    width: 1200px;
}
</style>